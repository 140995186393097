import { lazy, Suspense } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

import profilePicture from '../../assets/images/profile.webp'
import styles from './Homepage.module.scss'
import { faGithub, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faKeyboard } from "@fortawesome/free-regular-svg-icons"

const ContactMe = lazy(() => import('../ContactMe'))

const Homepage = () => {
    return (
        <div className={styles.container}>
            <ul className={styles.title}>
                <li>
                    <img src={profilePicture} alt='Profile'/>
                </li>
                <li>
                    <h1>Ali Ranjbar</h1>
                </li>
                <li>
                    <h2>CS Ph.D. Student</h2>
                </li>
                <li>
                    <a href='https://www.eecs.psu.edu/departments/EECS-Departments-Computer-Science-Engineering3.aspx'>
                        <h2>Penn State University</h2>
                    </a>
                </li>
                <li>
                    <Suspense fallback={<div>Loading...</div>}>
                        <ContactMe />
                    </Suspense>
                </li>
            </ul>
            <ul className={styles.icons}>
                <li>
                    <a href='https://github.com/mustbastani'>
                        <FontAwesomeIcon icon={faGithub} size='2x' />
                    </a>
                </li>
                <li>
                    <a href='https://twitter.com/mustbastani'>
                        <FontAwesomeIcon icon={faTwitter} size='2x' />
                    </a>
                </li>
                <li>
                    <a href='/files/cv_ali_ranjbar.pdf'>
                        <i className={classNames(styles.ai, styles['ai-cv'], styles['ai-2x'])}></i>
                    </a>
                </li>
                <li>
                    <a href='https://10fastfingers.com/user/1044991/'>
                        <FontAwesomeIcon icon={faKeyboard} size='2x' />
                    </a>
                </li>
            </ul>
            <div className={styles.content}>
                <div className={styles.interests}>
                    <div>Interests:</div>
                    <ul>
                        <li>Vulnerability Discovery</li>
                        <li>Operating Systems Security</li>
                        <li>LibAFL, angr, Ghidra, and PANDA.re</li>
                        <li>Google's Golang and Rust</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Homepage