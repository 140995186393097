import React from 'react'
import { 
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom'

import Homepage from './Components/Homepage'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact={true} path="/" element={<Homepage />} />
        <Route path="/" render={() => <div>404</div>} />
      </Routes>
    </Router>
  );
}

export default App;
